import { useCallback, useEffect, useMemo, useRef } from 'react';
import type { JSX } from 'react';

import { useLocation } from 'react-router';

import { Translate, useI18n } from '@change-corgi/core/react/i18n';
import { useTracking } from '@change-corgi/core/react/tracking';
import { Button, Link } from '@change-corgi/design-system/components/actions';
import { Box, Flex } from '@change-corgi/design-system/layout';
import { derivedStyle } from '@change-corgi/design-system/theme';
import { Heading, Text } from '@change-corgi/design-system/typography';

import { useMutableCookiePrefs } from 'src/app/shared/hooks/session';

import { trackCookieWallClick, trackCookieWallView } from '../api/track';

type Props = {
	onAccept?: (consentText: string) => void;
	onReject?: (consentText: string) => void;
	onError?: () => void;
};

function useAcceptedPrefsObject() {
	const { translate } = useI18n();
	// the string displayed in the cookie wall
	const consentText = translate('fe.components.cookie_wall.accept_button');
	return useMemo(
		() => ({
			preferences: {
				consent_text: consentText,
				status: true,
			},
			analytics: {
				consent_text: consentText,
				status: true,
			},
			marketing: {
				consent_text: consentText,
				status: true,
			},
		}),
		[consentText],
	);
}

function useRejectedPrefsObject() {
	const { translate } = useI18n();
	// the string displayed in the cookie wall
	const consentText = translate('fe.components.cookie_wall.accept_button');
	return useMemo(
		() => ({
			preferences: {
				consent_text: consentText,
				status: false,
			},
			analytics: {
				consent_text: consentText,
				status: false,
			},
			marketing: {
				consent_text: consentText,
				status: false,
			},
		}),
		[consentText],
	);
}

function useAllPrefsObject() {
	const accepted = useAcceptedPrefsObject();
	const rejected = useRejectedPrefsObject();
	return useMemo(
		() => ({
			accepted,
			rejected,
		}),
		[accepted, rejected],
	);
}

const MOBILE_TEXT_HEIGHT = 96;

// eslint-disable-next-line max-lines-per-function
export function CookieWallContainer({ onAccept, onReject, onError }: Props): JSX.Element | null {
	const track = useTracking();
	const location = useLocation();
	const wallEl = useRef<HTMLDivElement>(null);
	const { translate } = useI18n();
	// the string displayed in the cookie wall
	const consentText = translate('fe.components.cookie_wall.accept_button');
	const prefs = useAllPrefsObject();
	const [, saveCookiePrefs] = useMutableCookiePrefs();

	useEffect(() => {
		void trackCookieWallView(track);
	}, [track]);

	const handleAccept = useCallback(async () => {
		try {
			await saveCookiePrefs(prefs.accepted);
			void onAccept?.(consentText);
			void trackCookieWallClick('accept', track);
			return true;
		} catch {
			onError?.();
			return false;
		}
	}, [saveCookiePrefs, prefs, consentText, track, onAccept, onError]);

	const handleReject = useCallback(async () => {
		try {
			await saveCookiePrefs(prefs.rejected);
			void onReject?.(consentText);
			void trackCookieWallClick('reject', track);
			return true;
		} catch {
			onError?.();
			return false;
		}
	}, [saveCookiePrefs, prefs, consentText, track, onReject, onError]);

	const handleManage = useCallback(() => {
		void trackCookieWallClick('manage', track);
	}, [track]);

	return (
		<Box
			sx={{
				position: 'fixed',
				backgroundColor: 'primary-white',
				boxShadow: '0 0 10px 10px rgb(0 0 0 / 20%)',
				width: '100%',
				bottom: 0,
				left: 0,
				zIndex: 'cookieWall',
			}}
			data-testid="cookie-wall-modal"
		>
			<Flex
				sx={{
					backgroundColor: 'primary-white',
					width: 'inherit',
					justifyContent: 'center',
				}}
				ref={wallEl}
				role="dialog"
				aria-labelledby="cookie-wall-heading"
				aria-describedby="cookie-wall-description"
			>
				<Box sx={{ maxWidth: 980 }} pt={[24, 40]} pb={[16, 40]} mx={24}>
					<Heading size="h3" as="h1" id="cookie-wall-heading" mb={8}>
						<Translate value="fe.components.cookie_wall.title" />
					</Heading>
					<Flex sx={{ flexDirection: ['column', 'row'] }}>
						<Box
							sx={{
								flex: 1,
								position: 'relative',
								maxWidth: 580,
								overflow: 'hidden',
								height: [MOBILE_TEXT_HEIGHT, 'auto'],
								maxHeight: [MOBILE_TEXT_HEIGHT, 'none'],
								// eslint-disable-next-line @typescript-eslint/naming-convention
								'&:focus': {
									height: 'auto',
									maxHeight: 'none',
									// eslint-disable-next-line @typescript-eslint/naming-convention
									'[data-showmore]': {
										display: 'none',
									},
								},
							}}
							mr={[0, 60]}
							my={8}
							tabIndex={0}
						>
							<Box sx={{ height: '100%', overflow: 'hidden' }}>
								<Text as="p" size="default" id="cookie-wall-description">
									<Translate value="fe.components.cookie_wall.desc" />
								</Text>
							</Box>
							<Box
								sx={{
									position: 'absolute',
									background: 'linear-gradient(to right, rgba(255, 255, 255, 0), white 66px)',
									pl: 80, // should be slightly more than the right side of the gradient
									display: ['block', 'none'],
									zIndex: derivedStyle(({ zIndices }) => zIndices.cookieWall + 1),
									bottom: 0,
									right: 0,
								}}
								aria-hidden
								data-showmore
							>
								<Text as="div" size="default" color="neutral-grey700" sx={{ textDecoration: 'underline' }}>
									<Translate value="fe.pages.common.read_more" />
								</Text>
							</Box>
						</Box>
						<Box my={[8, 16]}>
							<Flex sx={{ flexDirection: ['row', 'column'], justifyContent: 'flex-start', gap: [8, 16] }} mb={16}>
								<Button
									size={['small', 'medium']}
									sx={{ width: ['50%', 300] }}
									onClick={handleAccept}
									data-testid="cookie-wall-accept"
								>
									<Translate value="fe.components.cookie_wall.accept_button" />
								</Button>
								<Button
									variant="secondary"
									size={['small', 'medium']}
									sx={{ width: ['50%', 300] }}
									onClick={handleReject}
									data-testid="cookie-wall-reject"
								>
									<Translate value="fe.components.cookie_wall.reject_button" />
								</Button>
							</Flex>
							<Flex mr={[8, 0]} sx={{ alignItems: 'center', justifyContent: 'center' }}>
								<Link
									to={`/account_settings/manage_cookies?redirect_to=${encodeURIComponent(
										location.pathname + location.search + location.hash,
									)}`}
									data-pass-thru
									onClick={handleManage}
									data-testid="cookie-wall-manage"
								>
									<Translate value="fe.components.cookie_wall.manage_cookies_button" />
								</Link>
							</Flex>
						</Box>
					</Flex>
				</Box>
			</Flex>
		</Box>
	);
}
