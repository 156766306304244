import { useEffect } from 'react';
import type { JSX } from 'react';

import { Translate } from '@change-corgi/core/react/i18n';
import { VisuallyHidden } from '@change-corgi/design-system/a11y';
import { Icon } from '@change-corgi/design-system/components/icon';
import { Html } from '@change-corgi/design-system/html';
import { iconClose } from '@change-corgi/design-system/icons';
import { Box, Container, Flex } from '@change-corgi/design-system/layout';
import { buttonResetStyles } from '@change-corgi/design-system/reset';

type Props = {
	onDismiss?: () => void;
	onView?: () => void;
	onLinkClick?: () => void;
	copy: Readonly<{ body: string }>;
};

export function TermsOfServiceBanner({ onDismiss, copy, onView, onLinkClick }: Props): JSX.Element | null {
	useEffect(() => {
		onView?.();
	}, [onView]);

	return (
		<Box sx={{ backgroundColor: 'primary-greyBackground' }}>
			<Container sx={{ minHeight: 42, position: 'relative' }} role="alert" data-nosnippet>
				<Flex
					py={16}
					pl={10} // aligns with header
					mr={48} // space for dismiss icon
					sx={{ alignItems: 'center' }}
				>
					<Html
						sx={{
							a: {
								color: 'inherit',
								// eslint-disable-next-line @typescript-eslint/naming-convention
								'&:hover,&:focus': {
									color: 'inherit',
								},
							},
						}}
						html={copy.body}
						onClickLink={onLinkClick}
					/>
				</Flex>
				<button
					type="button"
					sx={{
						...buttonResetStyles,
						position: 'absolute',
						top: 8,
						right: 4, // aligns with the avatar
						p: 8,
						cursor: 'pointer',
					}}
					onClick={onDismiss}
					data-testid="tos-banner-dismiss"
				>
					<Icon icon={iconClose} size="xl" />
					{/* using this approach for a11y for wider screen reader support
					https://www.sarasoueidan.com/blog/accessible-icon-buttons/ (technique #1) */}
					<VisuallyHidden>
						<Translate value="corgi.components.banner.close" />
					</VisuallyHidden>
				</button>
			</Container>
		</Box>
	);
}
