import { FCM_TOS_LEGAL_NOTICE_BANNER } from '@change-corgi/fcm/configs';

import { createFcmFunctions } from 'src/app/shared/hooks/fcm';

export const { useFcm: useConfigFcm, getFcm: getConfigFcm } = createFcmFunctions(
	{
		tosConfig: FCM_TOS_LEGAL_NOTICE_BANNER,
	},
	{ hook: true, getter: true },
);
