import type { TrackingFn } from '@change-corgi/core/react/tracking';

export async function trackCookieWallClick(
	buttonName: 'manage' | 'accept' | 'reject',
	track: TrackingFn,
): Promise<boolean> {
	return track('cookie_wall_click', {
		button_click: buttonName,
	});
}

export async function trackCookieWallView(track: TrackingFn): Promise<boolean> {
	return track('cookie_wall_view');
}
