import React, { forwardRef } from 'react';

import { Link } from '@change-corgi/core/react/router';

type TomatoDSLinkProps = Omit<React.AnchorHTMLAttributes<HTMLAnchorElement>, 'href'> & {
	href: string;
};

export const TomatoDSLink = forwardRef(({ href, ...rest }: TomatoDSLinkProps, ref: React.Ref<HTMLAnchorElement>) => (
	<Link to={href} ref={ref} {...rest} />
));

TomatoDSLink.displayName = 'TomatoDSLink';
