// This is a temporary solution to pass prefetched data from react-router to the PrefetchProvider

import type { PropsWithChildren } from 'react';

import flatten from 'lodash/flatten';
import type { RouteObject, ShouldRevalidateFunctionArgs } from 'react-router';
import { ScrollRestoration, useLoaderData, useLocation, useParams } from 'react-router';

import { Redirect } from '@change-corgi/core/react/router';

import { PrefetchProvider } from 'src/shared/prefetch';
import type { AppRedirect, AppRoute } from 'src/shared/routes';
import type { Session } from 'src/shared/session';

import { ErrorBoundary, InvalidRoute } from 'src/app/app/error';
import { DefaultLayout, getLayoutProps } from 'src/app/app/layout';
import { CheckRestrictedAccess } from 'src/app/app/restrictedAccess';
import type { RouteLoaderFunctionFactory } from 'src/app/app/router';
import type { LoadingContext } from 'src/app/app/topLoadingBar';
import { PageTracking } from 'src/app/app/tracking';
import { APP_REDIRECTS, APP_ROUTES, Preload } from 'src/app/routes';
import type { AppCache } from 'src/app/shared/hooks/cache';
import { FocusFallbackProvider } from 'src/app/shared/hooks/focus';
import { useQueryString } from 'src/app/shared/hooks/location';

import { App, InnerApp } from './App';
import type { AppOptions } from './types';

function PathBasedRedirect({ buildUrl, reason }: Omit<AppRedirect, 'path'>): JSX.Element {
	const { pathname: path, search: queryString } = useLocation();
	const params = useParams();
	const query = useQueryString();

	return <Redirect to={buildUrl({ path, query, queryString, params })} reason={reason} />;
}

function useLocationKey({ disableFullRerenderForParams }: AppRoute, path: string): string {
	const location = useLocation();
	const params = useParams();

	if (disableFullRerenderForParams) {
		const filteredParams = Object.entries(params).filter(([key]) => !disableFullRerenderForParams.includes(key));
		return `${path}|${filteredParams.map(([key, value]) => `${key}=${value}`).join('|')}`;
	}
	return location.pathname;
}

// Ultimately, we should be able to use react-router's built-in prefetching capabilities
function PassPrefetchedData({
	children,
	route,
	path,
}: PropsWithChildren<{ route: AppRoute; path: string }>): JSX.Element {
	// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
	const prefetchData = useLoaderData();
	const locationKey = useLocationKey(route, path);

	if (!prefetchData) return <>{children}</>;

	return (
		<PrefetchProvider
			/* We are using this technique to make sure that navigating within the same route will not result in partial UI updates (e.g. the sign count, then the hero image) */
			key={locationKey}
			{...prefetchData}
		>
			{children}
		</PrefetchProvider>
	);
}

type Options = AppOptions & {
	context?: { cache?: AppCache; session?: Session };
	loadingContext?: LoadingContext;
	loaderFactory?: RouteLoaderFunctionFactory;
};

// we are using this to prevent a data load on a simple query params change
const shouldRevalidate = (
	{ disableFullRerenderForParams }: AppRoute,
	{ currentUrl, nextUrl, currentParams, nextParams }: ShouldRevalidateFunctionArgs,
) => {
	if (disableFullRerenderForParams) {
		const changedParams = Object.keys(currentParams).filter((param) => currentParams[param] !== nextParams[param]);
		return changedParams.some((param) => !disableFullRerenderForParams.includes(param));
	}
	return currentUrl.pathname !== nextUrl.pathname;
};

export function createRoutes({ context = {}, loadingContext, loaderFactory, ...options }: Options): RouteObject[] {
	return [
		{
			id: 'root',
			element: (
				<FocusFallbackProvider>
					{/* we don't want a query param change to result in a scroll to top */}
					<ScrollRestoration getKey={({ pathname }) => pathname} />
					<App {...options} />
				</FocusFallbackProvider>
			),
			children: [
				{
					id: 'inner-root',
					element: (
						<InnerApp
							history={options.history}
							utilities={options.utilities}
							loadingContext={loadingContext}
							context={context}
						/>
					),
					ErrorBoundary,
					children: flatten([
						...APP_ROUTES.map((route) => {
							const { id, path, component, frame, preload } = route;
							// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-assignment, @typescript-eslint/consistent-type-assertions
							const RouteComponent = component as any; // TODO find a way to properly type this
							const paths = Array.isArray(path) ? path : [path];
							const loader = loaderFactory?.(route);
							const layoutProps = getLayoutProps(frame);
							return paths.map(
								// eslint-disable-next-line @typescript-eslint/no-shadow
								(path, pathIdx): RouteObject => ({
									id: paths.length > 1 ? `${id}:${pathIdx}` : id,
									path,
									element: (
										<PassPrefetchedData route={route} path={path}>
											<CheckRestrictedAccess route={route}>
												<Preload preload={preload}>
													<PageTracking route={route} />
													<DefaultLayout hideBrowseLink={options.hideBrowseLink} {...layoutProps}>
														<RouteComponent />
													</DefaultLayout>
												</Preload>
											</CheckRestrictedAccess>
										</PassPrefetchedData>
									),
									shouldRevalidate: (args) => shouldRevalidate(route, args),
									ErrorBoundary,
									loader,
								}),
							);
						}),
						...APP_REDIRECTS.map(({ path, buildUrl, reason }, index) => ({
							id: `redirect-${index}`,
							path,
							element: <PathBasedRedirect buildUrl={buildUrl} reason={reason} />,
						})),
						{
							id: 'invalid-route',
							path: '/*',
							element: (
								<DefaultLayout hideBrowseLink={options.hideBrowseLink}>
									<InvalidRoute />
								</DefaultLayout>
							),
						},
					]),
				},
			],
		},
	];
}
