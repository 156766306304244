import React from 'react';

import { PrimitivesProvider } from '@change/tomato-primitives';

import { TomatoDSLink } from './components/TomatoDSLink';

type Props = { children: React.ReactNode };

// using a const because of a hook dep in the usePrimitive hook implementation
// TODO improve usePrimitive implementation to avoid this issue
const primitives = { a: TomatoDSLink };

export const TomatoDSPrimitivesProvider: React.FC<Props> = ({ children }: Props) => (
	<PrimitivesProvider primitives={primitives}>{children}</PrimitivesProvider>
);
